// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-article-tsx": () => import("./../../../src/components/article.tsx" /* webpackChunkName: "component---src-components-article-tsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-anti-resume-tsx": () => import("./../../../src/pages/anti-resume.tsx" /* webpackChunkName: "component---src-pages-anti-resume-tsx" */),
  "component---src-pages-auto-archive-privacy-policy-tsx": () => import("./../../../src/pages/auto-archive/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-auto-archive-privacy-policy-tsx" */),
  "component---src-pages-auto-archive-tos-tsx": () => import("./../../../src/pages/auto-archive/tos.tsx" /* webpackChunkName: "component---src-pages-auto-archive-tos-tsx" */),
  "component---src-pages-auto-archive-tsx": () => import("./../../../src/pages/auto-archive.tsx" /* webpackChunkName: "component---src-pages-auto-archive-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-music-tsx": () => import("./../../../src/pages/music.tsx" /* webpackChunkName: "component---src-pages-music-tsx" */),
  "component---src-pages-resume-tsx": () => import("./../../../src/pages/resume.tsx" /* webpackChunkName: "component---src-pages-resume-tsx" */)
}

